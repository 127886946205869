<template>
  <v-card
    class="text-center"
  >
    <v-card-title>
      {{ $t(title) }}
    </v-card-title>
    <v-card-text
      @click="nextStep"
    >
      <QRCode
        v-model="data"
        class="qrcode"
      />
    </v-card-text>
    <v-btn
      class="mt-4 mb-8"
      outlined
      color="warning"
      @click="handleBack"
    >
      {{ $t('Back') }}
    </v-btn>
  </v-card>
</template>

<script>
import QRCode from '@/components/QRCode.vue'
import { required } from '@core/utils/validation'
import { mapMutations } from 'vuex'
import { ACTION_SCAN_QR } from '@/utils/shareBoxHelper'

export default {
  components: { QRCode },
  data() {
    return {
      data: '',
      validators: {
        required,
      },
    }
  },
  computed: {
    actionType() {
      return this.$route.meta.action || ''
    },
    title() {
      switch (this.actionType) {
        case ACTION_SCAN_QR.BORROW:
          return 'ScanBorrowItem'
        case ACTION_SCAN_QR.CREATE:
          return 'ScanCreateItem'
        case ACTION_SCAN_QR.RETURN:
          return 'ScanReturnItem'

        default:
          return ''
      }
    },
  },
  watch: {
    data(val) {
      if (val) {
        this.handleQrData()
      }
    },
  },
  methods: {
    ...mapMutations('sharebox', ['setQrData']),
    nextStep() {
      this.router.push('/sharebox')
    },
    handleBack() {
      this.setQrData(null)
      this.$router.back()
    },
    handleQrData() {
      try {
        const qrData = JSON.parse(this.data)
        this.setQrData({
          ...qrData,
          actionType: this.actionType,
        })
        switch (this.actionType) {
          case ACTION_SCAN_QR.BORROW:
            this.$router.push({
              name: 'sharebox-detail',
              params: {
                id: qrData.id_item,
              },
            })
            break
          case ACTION_SCAN_QR.CREATE:
            this.$router.push({
              name: 'staff-sharebox',
            })
            break

          case ACTION_SCAN_QR.RETURN:
            this.$router.push({
              name: 'staff-sharebox-detail',
              params: {
                id: qrData.id_item,
              },
            })
            break

          default:
            break
        }
      } catch (error) {
        this.$store.commit('snackbar/show', {
          message: error?.message || 'QR invalid',
          type: 'error',
        })

        console.error(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.qrcode {
  border: 2px solid var(--v-primary-base);
  padding: 2px;
}
</style>
